.element-link {
  display: inline;
  position: relative;
}

.element-link .popup {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 6px 10px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid lightgray;
}

.element-link .popup a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-right: 10px;
  border-right: 1px solid lightgrey;
}

.element-link .popup button {
  border: none;
  background: transparent;
}

.element-link .popup button:hover {
  color: rebeccapurple;
  cursor: pointer;
}
